.selectMeasureWeight {
	@apply px-3 h-10 grow cursor-pointer w-12 rounded-lg bg-white border border-gray-100 text-black;
	@apply focus:outline-none focus:ring-primary-400 focus:border-primary-400 focus:ring-1 focus:ring-offset-0 #{!important};
	-webkit-appearance: none;
	-moz-appearance: none;
	text-indent: 1px;
	text-overflow: '';
}
:global(.dark) .selectMeasureWeight {
	@apply bg-gray-800 border-gray-700 text-white;
}
