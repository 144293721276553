.page {
	@apply px-5 pt-5 flex-1 flex flex-col w-full;
}
.container {
	@apply max-w-screen-3xl mx-auto  flex-1 flex flex-col w-full;
}
.page-main {
	@apply flex flex-col flex-1 w-full;
}
.header {
	@apply px-0 py-0 flex items-center justify-between min-h-9;
}
.header-left {
	@apply flex items-center gap-2;
}
.header-right {
	@apply flex items-center gap-2;
}
.title {
	@apply text-xl font-semibold text-gray-900;
}
:global(.dark) .title {
	@apply text-white;
}
.main {
	@apply px-0 pt-4 pb-8 flex-1 flex flex-col w-full;
}
.footer {
	@apply px-0 py-0;
}
