.upload {
    @apply w-full  h-full p-6 text-gray-500 rounded-lg border-2 flex flex-col items-center relative justify-center border-dashed  border-blue-500/30 bg-white  h-full;
}
:global(.dark) .upload {
	@apply border-blue-500/30 bg-gray-800 text-gray-400;
}
.active {
	@apply border-primary-200 bg-primary-50/50 text-primary-400;
}
:global(.dark) .active {
	@apply border-primary/20 bg-primary/10 text-primary-400;
}
.start {
	@apply flex-col;
}
.container {
	@apply w-full flex h-full relative cursor-pointer;
}
.img {
	@apply w-full object-contain rounded-lg absolute z-10;
}
.drag {
}
.icon {
	@apply w-8 h-8;
}
.text {
	@apply text-sm;
}
.label {
	@apply text-gray-600 pl-3 pb-0.5;
}
:global(.dark) .label {
	@apply text-gray-400;
}

.error {
	@apply border-red-200 bg-red-50/50 text-red-400;
}
:global(.dark) .error {
	@apply border-red-500/20 bg-red-500/10 text-red-400;
}
.block {
	opacity: 0;
	@apply absolute  flex gap-3 flex-wrap  items-center justify-center w-full px-6 text-center;
}
.wrapper {
	@apply w-full h-full flex flex-col;
}

.button{
    @apply flex flex-col items-center gap-1 text-blue-500;
}
