.page {
	@apply mt-2 flex-1 flex flex-col w-full;
}
.container {
	@apply max-w-6xl mx-auto  flex-1 flex flex-col w-full;
}
.header-content {
	@apply px-0 py-3 pb-5 flex flex-col;
}
.header-left {
	@apply flex flex-col gap-2 flex-3;
}
.header-right {
	@apply flex flex-col gap-2 flex-2;
}

.header {
    @apply px-5 flex flex-col w-full;
}

.header-top {
}
.header-main {
	@apply flex py-3  justify-between w-full gap-20;
}


.main {
	@apply px-0 px-5  pb-8 flex-1 flex flex-col w-full;
}
.footer {
	@apply px-0 py-0;
}

.content {
	@apply flex gap-6 pt-6 flex-1 flex-col;
}

.more {
	@apply flex flex-1 gap-20;
}
.info {
	@apply flex-1;
}
.info-header {
	@apply pb-3 border-b border-zinc-200 flex gap-8 items-center justify-between;
	h4 {
		@apply text-2xl font-semibold;
	}
	span {
		@apply text-zinc-600;
	}
}
:global(.dark) .info-header {
	@apply border-zinc-700;
	h4 {
		@apply text-zinc-100;
	}
	span {
		@apply text-zinc-400;
	}
}
.info-footer {
	@apply pt-3 border-t border-zinc-200 flex gap-2 items-center justify-between;
}
:global(.dark) .info-footer {
	@apply border-zinc-700;
}
.info-main {
}
.order {
	@apply flex-2;
}
.order-info {
	@apply flex flex-col  gap-3 flex-1;
}
.order-seller {
	@apply flex gap-4 items-center;
}

.total {
	@apply flex-2;
}
.total-main {
	@apply flex-1 flex-col py-2;
}
.info-line {
	@apply flex gap-4  justify-between flex-1 py-1.5;
}
:global(.dark) .info-line {
	@apply text-zinc-200;
}
.total-footer {
	@apply text-lg font-semibold;
}
.order-main {
	@apply py-4 flex flex-col;
}
.delivery-details {
}

.info-line-right {
	@apply text-right #{!important};
}

.map {
	@apply flex gap-20;
}
.status-history {
	@apply flex-1;
}
.order-field {
	@apply w-52 text-zinc-500 min-w-52;
}
.order-row {
	@apply flex items-center gap-3;
}
:global(.dark) .order-row {
	@apply text-zinc-100;
}
