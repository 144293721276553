.block {
	@apply grow flex flex-col;
}
.error-block {
	@apply px-3 text-sm text-red-400 h-0 invisible duration-200;
}
.error-active {
	@apply py-0.5 h-5 visible;
}
.label {
	@apply text-gray-600 pl-3 pb-0.5;
}
:global(.dark) .label {
	@apply text-gray-400;
}
.children {
	@apply flex gap-2 grow;
}
