.avatar {
	@apply h-7 w-7 min-w-7  rounded-full border border-gray-100 overflow-hidden flex items-center justify-center bg-gray-100;
	img {
		@apply h-full w-full object-cover rounded-full;
	}
}

:global(.dark) .avatar {
	@apply bg-gray-600 text-white border-gray-600;
}
