.buttonIcon {
	@apply active:scale-90 h-8 w-8 flex  items-center justify-center  rounded-lg cursor-pointer;
	@apply border border-transparent;
	svg {
		@apply h-6 w-6 text-gray-500;
	}

	&:hover {
		@apply bg-white;
		@apply border border-gray-100;
		svg {
			@apply text-gray-900;
		}
	}
}

:global(.dark) .buttonIcon {
	svg {
		@apply text-gray-300;
	}
	&:hover {
		@apply bg-gray-800;
		@apply border border-gray-700;
		svg {
			@apply text-gray-100;
		}
	}
}
