.create {
	@apply flex flex-col gap-7;
}
.row {
	@apply flex flex-row gap-x-3 gap-y-7 flex-wrap flex-1;
}
.col {
	@apply flex flex-col gap-7 flex-1;
}
.flexRight {
	@apply flex-2 #{!important};
}
