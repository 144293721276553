.productTable {
	@apply flex flex-col  flex-1 w-full;
}
.title {
	@apply text-xl font-semibold text-gray-900;
}
:global(.dark) .title {
	@apply text-gray-100;
}

.header {
	@apply flex mb-4 gap-6 justify-between items-center w-full;
}
