.roles {
  @apply flex flex-wrap  gap-1.5;
}

.role {
  @apply bg-white gap-2 text-zinc-900 rounded-full inline-flex items-center justify-center truncate h-7 px-2  text-center;
}

:global(.dark) .role {
  @apply bg-zinc-800 text-zinc-100;
}

.role-row {
  @apply flex gap-3;
}

:global(.dark) .button-delete, .button-delete {
    @apply h-6 w-6 border-2 rounded-full -mr-1  #{!important};
    svg {
        @apply text-red-500 w-5 h-5;
    }
&:hover {
        @apply bg-red-500/10 border-red-500/20;
        svg {
            @apply text-red-500 ;
        }
    }

}


:global(.dark) .button-create, .button-create {
    @apply h-7 w-7  rounded-full border-2 #{!important};
  svg {
      @apply text-green-500 w-5 h-5;
  }
  &:hover {
      @apply bg-green-500/10 border-green-500/20;
    svg {
        @apply text-green-500 ;
    }
  }

}