.paginations-block {
	@apply bg-gray-50 h-14 flex border-t-2 py-3 border-gray-100 gap-4 items-center justify-between;
}
:global(.dark) .paginations-block {
	@apply bg-gray-900 border-gray-800;
}
.paginations {
	@apply flex gap-2;
}
.result {
	@apply text-gray-900 flex items-center whitespace-nowrap;
}
:global(.dark) .result {
	@apply text-white;
}
.select {
	@apply rounded-lg bg-white text-gray-900 h-7 px-1 pr-2 block text-center  mr-4 cursor-pointer;
	@apply disabled:cursor-not-allowed;
	-webkit-appearance: none;
	-moz-appearance: none;
	text-indent: 1px;
	text-overflow: '';
}
:global(.dark) .select {
	@apply bg-gray-700 text-gray-100;
}

.icon {
	@apply h-5 w-5 stroke-2;
}
.button {
	@apply hover:bg-primary-50 hover:text-primary duration-150 h-8 w-8 flex items-center justify-center rounded-lg bg-white;
	@apply disabled:bg-white/40 disabled:text-gray-400 disabled:cursor-not-allowed;
}
:global(.dark) .button {
	@apply bg-gray-700 text-white hover:bg-primary/10 hover:text-primary;
	@apply disabled:bg-gray-700/40 disabled:text-gray-600;
}
.button-active {
	@apply disabled:bg-primary disabled:text-white bg-primary text-white hover:bg-primary hover:text-white;
}

:global(.dark) .button-active {
	@apply disabled:bg-primary disabled:text-white bg-primary text-white hover:bg-primary hover:text-white;
}
