.auth {
	@apply p-4 min-h-screen w-full flex items-center justify-center;
}
.content {
	@apply flex h-[400px] md:h-[450px]   p-10 rounded-lg items-center bg-white;
}
.left {
	@apply flex-1 pr-6 flex flex-col items-center md:items-start;
}
.right {
	@apply flex-1 hidden md:flex;
}
.logos {
	@apply flex items-center gap-6 font-bold italic text-gray-400;
}
.info {
	@apply flex flex-col mt-10 items-center md:items-start;
}
.title {
	@apply mb-8  text-2xl font-semibold;
}
.alert {
	@apply -mt-6 mb-8 flex items-center gap-2 text-sm bg-red-50 text-red-600 border border-red-200 inline-flex self-start px-1 py-0.5 pr-2 rounded-lg;
}
.alert-icon {
	@apply w-5 h-5;
}
.link {
	@apply mt-2 text-sm text-blue-500 inline-flex self-start hover:text-blue-600 hover:underline;
}
