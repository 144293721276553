.header {
	@apply px-5  bg-white border-b border-gray-100;
}
:global(.dark) .header {
	@apply bg-gray-800 border-gray-700;
}

.container {
	@apply max-w-screen-3xl mx-auto;
}
.main {
	@apply flex items-center justify-between h-12;
}
.left {
	@apply flex items-center gap-8 h-full;
}

.right {
	@apply flex items-center gap-4 h-full;
}
