.label-row {
	@apply flex items-center gap-3;
}
:global(.dark) .label-row {
	@apply text-zinc-100;
}

.label-field {
	@apply w-52 text-zinc-500 min-w-52;
}
