.sellerProductGroup {
	@apply inline-flex flex-col w-60 sticky top-4;
}
.title {
	@apply text-xl font-semibold text-gray-900;
}
:global(.dark) .title {
	@apply text-gray-100;
}
.header {
	@apply flex gap-6 justify-between items-center w-full mb-4;
}
.groups {
	@apply flex flex-col gap-1  bg-white p-1 rounded-lg border border-gray-100;
}
:global(.dark) .groups {
	@apply border-gray-700 bg-gray-800;
}
.group {
	div {
		@apply scale-0 duration-300 flex gap-0.5;
	}
	span {
		@apply flex-1 truncate;
	}
	&:hover {
		div {
			@apply scale-100;
		}
	}
	@apply px-0.5 pl-3  border-transparent h-9 flex cursor-pointer items-center rounded-lg  hover:bg-gray-50 border border-transparent hover:border-gray-100;
}
:global(.dark) .group {
	@apply hover:border-gray-600 hover:bg-gray-700 text-white;
}
.active {
	@apply bg-primary/10 hover:bg-primary/10 hover:border-primary text-primary border-primary;
}

:global(.dark) .active {
	@apply hover:bg-primary/10 hover:bg-primary/10 hover:border-primary text-primary border-primary;
}
