.logo {
	@apply cursor-pointer flex items-center gap-4;
}
.icon {
	@apply h-5;
	path {
		@apply fill-primary;
	}
}
:global(.dark) .icon {
	path {
		@apply fill-primary-300;
	}
}
