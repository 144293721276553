.feedback-input {
    @apply flex  gap-2;
    input{
        @apply bg-white text-zinc-700 border border-zinc-200 rounded-lg px-4 py-2;
    }

}


:global(.dark) .feedback-input {

    input{
        @apply bg-zinc-800 text-zinc-300 border-zinc-700;
    }
}


