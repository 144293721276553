.product {
	@apply flex items-center py-1;
}
.img {
	@apply h-20 w-20 rounded-lg;
	@apply bg-transparent border-transparent #{!important};
}
.info {
	@apply flex-1 px-4;
	.profile {
		@apply text-sm text-gray-500;
	}
	.name {
		@apply text-lg font-semibold;
	}
	.details {
		@apply text-sm text-gray-500 mt-1;
	}
}
:global(.dark) .info {
	.profile {
		@apply text-zinc-400;
	}
	.name {
		@apply text-zinc-100;
	}
	.details {
		@apply text-zinc-400;
	}
}
:global(.dark) .quantity {
	@apply text-zinc-100;
}
