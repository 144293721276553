.authForm {
	@apply flex flex-col gap-3 items-center w-64;
	button {
		@apply w-full;
	}
}
.title {
	@apply font-semibold text-lg text-center;
}
:global(.dark) .title {
	@apply text-white;
}
.groups {
	@apply flex flex-col gap-2 w-full;
}
.socials {
	@apply flex items-center gap-2 w-full;
}
