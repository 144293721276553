.user {
	@apply relative h-8 w-8;
}

.user-content {
	@apply border border-transparent  absolute -top-1 p-1 z-20 -right-1 flex flex-col  gap-1;
	@apply rounded-lg;
}

.avatar {
}
.user-button {
	@apply px-0 flex gap-2 items-center;
}
.user-info {
	@apply flex flex-col hidden;
}
.name {
	@apply text-base whitespace-nowrap font-medium;
}
.role {
	@apply text-sm bg-primary/10 border-primary/20 border text-primary rounded-full inline-flex px-1.5;
}
.menu {
	@apply hidden w-full  mt-2;
}
.open {
	@apply bg-white  border-gray-100;
	.user-info {
		@apply flex;
	}
	.user-button {
		@apply px-2;
	}
	.menu {
		@apply flex w-full;
	}
}

:global(.dark) .open {
	@apply bg-gray-700  border-gray-600;
}
:global(.dark) .name {
	@apply text-white;
}

.menu {
	@aplly flex flex-col flex-1;
	width: 100%;
}
.item {
	@apply border border-transparent w-full hover:border-gray-100 hover:bg-gray-50 rounded-xl flex items-center gap-2.5 pr-6 pl-2 py-1.5 cursor-pointer;
}
.icon {
	@apply w-6 h-6 text-gray-600;
}
:global(.dark) .item {
	@apply hover:border-gray-600 hover:bg-gray-800 text-white;
}
:global(.dark) .icon {
	@apply text-gray-300;
}
