.page {
	@apply mt-2 flex-1 flex flex-col w-full;
}
.container {
	@apply max-w-6xl mx-auto  flex-1 flex flex-col w-full;
}
.header-main {
	@apply px-0 py-3 pb-5  flex items-start justify-between;
}
.header {
	@apply px-5 flex flex-col w-full bg-white border-y border-gray-100;
}
:global(.dark) .header {
	@apply bg-gray-800 border-gray-700;
}
.header-left {
	@apply flex  gap-2 flex-col;
}
.header-right {
	@apply flex items-center gap-2;
}
.name {
	@apply text-xl font-semibold text-gray-900;
}
:global(.dark) .name {
	@apply text-white;
}
.main {
	@apply px-0 px-5  flex-1 flex flex-col w-full;
}
.footer {
	@apply px-0 py-0;
}

.name {
	@apply text-2xl font-bold text-gray-900;
}
.group {
	@apply text-gray-500;
}
:global(.dark) .name {
	@apply text-gray-100;
}

.content {
	@apply flex gap-6 pt-6 flex-1;
}
.info {
	@apply flex gap-12 items-end;
}
.img {
	@apply w-60 rounded-lg object-contain;
}
