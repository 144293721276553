.loading {
	@apply min-h-screen fixed top-0 left-0 w-full flex flex-col items-center justify-center bg-gray-900/30 opacity-0 duration-200;
	z-index: -1;
}
:global(.dark) .loading {
	@apply bg-gray-900/70;
}
.active {
	@apply opacity-100 z-50;
}
.spinner {
	@apply flex w-20 flex-wrap items-center justify-center gap-1;
}
.circle {
	@apply w-9 h-9 bg-primary rounded-full;
}
.secondary {
	@apply bg-white;
}
