.menu {
    @apply h-full;

}
.list {

    @apply gap-1 h-full flex;
}


.line{
    @apply h-0.5 w-full  rounded-full absolute left-0 -bottom-0.5;
}




.item {
	@apply text-zinc-500 relative duration-200 border h-full border-transparent  rounded-xl flex items-center gap-2.5 px-2 py-1 cursor-pointer;
    svg{
@apply w-5 h-5;}
&:hover{
        @apply text-zinc-700 ;
        .line{
            @apply bg-zinc-300;
        }
    }
}
:global(.dark) .item {
	@apply text-zinc-500 ;
	.icon {
		@apply text-gray-300;
	}
&:hover{
        @apply text-zinc-300 ;
        .line{
            @apply bg-zinc-600;
        }
    }
}
.icon {
	@apply w-5 h-5 text-gray-600;
}
.active {
    @apply text-black;
    .line{
        @apply bg-primary;
    }
&:hover{
        @apply text-black;
        .line{
            @apply bg-primary;
        }
    }
//	@apply hover:border-primary/20 hover:bg-primary/5 border-primary/40 bg-primary/5 text-primary;
//	.icon {
//		@apply text-primary;
//	}
}
:global(.dark) .active {
    @apply text-white ;
    .line{
        @apply bg-primary;
    }
&:hover{
        @apply text-white ;
        .line{
            @apply bg-primary;
        }
    }
//
//	@apply text-primary hover:bg-primary/10 hover:border-primary/20  bg-primary/10 border-primary/20;
//	.icon {
//		@apply text-primary;
//	}
}

.badge{
    @apply text-xs px-1 z-10 rounded-2xl bg-secondary  text-white absolute -right-2 -top-0;
}


