.img {
	@apply w-full h-full object-contain rounded-lg bg-white;
}
:global(.dark) .img {
	@apply bg-zinc-800;
}

.placeholder {
	@apply p-6;
	svg {
		@apply w-full h-full text-zinc-500;
	}
}
