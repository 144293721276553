.order-history-header {
	@apply pb-3 border-b border-zinc-200 flex gap-2 items-center justify-between;
	h4 {
		@apply text-2xl font-semibold;
	}
	span {
		@apply text-zinc-600;
	}
}
:global(.dark) .order-history-header {
	@apply border-zinc-700;
	h4 {
		@apply text-zinc-100;
	}
	span {
		@apply text-zinc-400;
	}
}
.order-history-footer {
	@apply pt-3 border-t border-zinc-200 flex gap-2 items-center justify-between;
}
:global(.dark) .order-history-footer {
	@apply border-zinc-700;
}
.order-history-main {
}
.status-line {
	@apply flex gap-2 items-center justify-between flex-1 py-1.5;
}
:global(.dark) .status-line {
	@apply text-zinc-200;
}
