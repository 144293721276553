.orderLandingPoint {
    @apply flex flex-col ;
}

.coordinates{
    @apply flex items-center gap-2 text-sm bg-zinc-200  rounded-xl px-2 py-0.5;
}

:global(.dark) .coordinates {
    @apply bg-zinc-700;
}