.productShowDetails {
}
.name {
	@apply text-3xl font-semibold;
}

:global(.dark) .name {
	@apply text-white;
}
.details {
	@apply flex gap-6 mt-8;
}
.left {
	@apply flex-1;
}
.right {
	@apply flex-1 flex gap-8 flex-col;
}

.title {
	@apply text-xl font-medium;
}
:global(.dark) .title {
	@apply text-white;
}

.text {
	@apply mt-1;
}
:global(.dark) .text {
	@apply text-white;
}

.subtitle {
	@apply text-gray-500;
}
:global(.dark) .subtitle {
	@apply text-gray-300;
}

.subtext {
	@apply text-gray-500;
}
:global(.dark) .subtext {
	@apply text-gray-300;
}
.divider {
	@apply border border-gray-100;
}
:global(.dark) .divider {
	@apply border-gray-800;
}

.list {
	@apply flex gap-8;
}
