.user {
	@apply flex items-center gap-2;
}
.info {
@apply flex flex-col text-zinc-800;
}

:global(.dark) .info {
    @apply text-zinc-100;
}

.email{
@apply text-sm;}
