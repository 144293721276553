.button {
	@apply active:scale-95 h-9 px-3.5 font-medium flex items-center gap-2 justify-center rounded-full  duration-150;
	svg {
		@apply h-6 w-6 text-gray-800;
	}
	&:hover {
		svg {
			@apply text-gray-800;
		}
	}
	@apply disabled:cursor-not-allowed disabled:bg-zinc-200 disabled:text-zinc-500;
}
:global(.dark) .button {
	svg {
		@apply text-gray-200;
	}
	&:hover {
		svg {
			@apply text-gray-200;
		}
	}
}

.primary {
	@apply bg-primary text-white text-sm hover:bg-primary-500;
}

.secondary {
	@apply text-gray-900 text-sm  border border-gray-100;
	@apply bg-white hover:bg-gray-100 hover:border-gray-200 #{!important};
}

:global(.dark) .secondary {
	@apply text-gray-200 hover:bg-gray-700 border-gray-700;
	@apply bg-gray-800 hover:bg-gray-700 hover:border-gray-600 #{!important};
}

.hover {
	@apply text-gray-900 text-sm  border border-transparent;
	@apply bg-transparent hover:bg-white hover:border-gray-100 #{!important};
}

:global(.dark) .hover {
	@apply text-gray-200 hover:bg-gray-700 border-transparent;
	@apply bg-transparent hover:bg-gray-800 hover:border-gray-700 #{!important};
}
