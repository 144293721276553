.input {
	@apply h-10 w-full px-3 rounded-lg py-0.5  border border-gray-100 bg-white;
	@apply focus:outline-none focus:ring-primary-400 focus:border-primary-400 focus:ring-1 focus:ring-offset-0 #{!important};
}
:global(.dark) .input {
	@apply border;
	@apply bg-gray-800 border-gray-700 #{!important};
}
.block {
	@apply grow flex flex-col;
}
.error {
}

:global(.dark) .input {
	@apply bg-gray-700 border-gray-500 text-white;
}

.error-block {
	@apply px-3 text-sm text-red-400 h-0 invisible duration-200;
}
.error-active {
	@apply py-0.5 h-5 visible;
}
.label {
	@apply text-gray-600 pl-3 pb-0.5;
}
:global(.dark) .label {
	@apply text-gray-400;
}
