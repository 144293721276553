.orderTable {
	@apply flex flex-col  flex-1 w-full;
}

.button-customer-password-reset{
    &:hover{
        @apply bg-orange-500/10 border-orange-500/20;
      svg{
          @apply text-orange-500 }
    }
}