.feedback-message {
    @apply inline-flex flex-col gap-2 ;
}


.feedback-user{
    @apply px-4;
}
.feedback-text {
    @apply bg-white text-zinc-700 border border-zinc-200 rounded-lg px-4 py-2;
}
.feedback-text-main {
    @apply bg-gradient-to-r from-blue-500 to-purple-500 text-white #{!important};
}

:global(.dark) .feedback-text {
    @apply bg-zinc-800 text-zinc-300 border-zinc-700;
}


