@tailwind base;
@layer base {
	html,
	body {
		@apply dark:bg-gray-900  bg-gray-50;
	}

	html {
		min-height: 100%;
		display: flex;
		flex-direction: column;
	}

	body {
		flex: 1;
		flex-direction: column;
		display: flex;
		min-width: 1250px;
	}

	#root {
		flex: 1;
		display: flex;
		min-height: 100%;
		flex-direction: column;
	}

	.h-auto {
		height: auto !important;
	}

	textarea {
		@apply resize-none;
		@apply pt-1.5 min-h-24 #{!important};
	}

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}

	::-webkit-scrollbar {
		width: 0;
	}
	.toaster {
		@apply dark:border-zinc-700 dark:bg-zinc-800 dark:text-zinc-200 bg-white text-zinc-800  border border-zinc-100 #{!important};
	}
}

@tailwind components;
@tailwind utilities;
