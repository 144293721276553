.block {
	@apply flex-1  flex flex-col;
}
.table {
	@apply flex-1;
}
.thead {
	@apply sticky top-0 bg-gray-50;
}
:global(.dark) .thead {
	@apply bg-gray-900;
}
.tr {
	@apply flex border-t border-gray-100  text-gray-900;
}
:global(.dark) .tr {
	@apply border-gray-700 text-white;
}

.tr-active{
    @apply bg-primary-500/10 hover:bg-primary-500/10 #{!important};
}

.tbody {
	@apply flex flex-col;
	.tr {
		@apply hover:bg-gray-100/50;
	}
}

:global(.dark) .tbody {
	.tr {
		@apply hover:bg-gray-800/50;
	}
}
.th,
.td {
	@apply px-2 py-2;
}
.divider {
	@apply w-full flex-1  bg-gray-100 flex;
	max-width: 1px;
}
:global(.dark) .divider {
	@apply bg-gray-700;
}

.th {
	@apply text-gray-500 uppercase font-medium text-sm;
	@apply flex items-center justify-between;
}
:global(.dark) .th {
	@apply text-gray-300;
}
.td {
	// @apply flex-1;
}
.head-icons {
	@apply flex flex-col;
}

.head-icon {
	@apply w-2.5 h-2.5 text-gray-300;
	stroke-width: 4;
}
:global(.dark) .head-icon {
	@apply text-gray-600;
}
.head-icon-active {
	@apply text-black;
}
:global(.dark) .head-icon-active {
	@apply text-white;
}
.head-icon:last-child {
	@apply -mt-0.5;
}

// .filters {
// 	@apply py-3;
// }
